import { defineStore } from 'pinia'
import Breadcrumb from '@/models/Breadcrumb'
import useStore from '@/stores'
import { useUserStore } from '@/stores/user'
import clone from 'just-clone';

export interface Menu {
    id: string,
    title: string,
    router?: string,
    children?: Menu[],
    icon?: string,
    only?: string[],
    except?: string[]
}

const items = [
        {
            id: 'dashboard.index',
            title: "Dashboard",
            icon: "bi bi-bar-chart-steps",
            only: ['root', 'manager'],
            children: [
                {
                    id: 'dashboard.income',
                    title: "Dashboard",
                    icon: "bi bi-bar-chart-steps",
                    router: "/dashboard",
                },
                {
                    id: 'dashboard.import',
                    title: "Income",
                    icon: "bi bi-wallet2",
                    only: ['root'],
                    router: "/dashboard/income"
                },
            ] as Menu[],
        },
        {
            id: 'todo.index',
            title: "Todos",
            icon: "bi bi-ui-checks",
            router: "/todo",
        },
        {
            id: "project",
            title: "Project",
            icon: "bi bi-file-post",
            children: [
                {
                    id: 'project.index',
                    title: "Projects",
                    icon: "bi bi-folder2-open",
                    router: "/project/index"
                },
                {
                    id: 'project.list',
                    title: "Project List",
                    icon: "bi bi-table",
                    router: "/project/list"
                },
                {
                    id: 'project.kanban',
                    title: "Project Board",
                    icon: "bi bi-kanban-fill",
                    router: "/project/kanban"
                },
                {
                    id: 'project.week',
                    title: "Project Week",
                    icon: "bi bi-calendar-week",
                    router: "/project/week"
                },
                {
                    id: 'project.calendar',
                    title: "Project Calendar",
                    icon: "bi bi-calendar3",
                    router: "/project/calendar"
                },
            ] as Menu[],
        },
        {
            id: 'bundle.index',
            title: "Bundle pack",
            icon: "bi bi-window-stack",
            router: "/bundle",
            only: ['root', 'manager', 'accountant', 'account_executive'],
        },
        {
            id: "content",
            title: "Content",
            icon: "bi bi-passport",
            children: [
                {
                    id: 'content.unfinished',
                    title: "กรอกข้อมูลให้ครบ",
                    icon: "bi bi-emoji-frown",
                    router: "/content/unfinished"
                },
                {
                    id: 'content.me',
                    title: "My Contents",
                    icon: "bi bi-person-arms-up",
                    router: "/content/me"
                },
                {
                    id: 'content.index',
                    title: "Contents",
                    icon: "bi bi-table",
                    router: "/content/index"
                },
                {
                    id: 'content.calendar',
                    title: "Content Calendar",
                    icon: "bi bi-calendar3",
                    router: "/content/calendar"
                },
            ] as Menu[],
        },
        {
            id: 'client.index',
            title: "รายชื่อลูกค้า",
            icon: "bi bi-building",
            router: "/client",
        },
        {
            id: "billing",
            title:  "เก็บเงิน",
            icon: "bi bi-piggy-bank",
            only: ['root', 'manager', 'accountant', 'account_executive'],
            children: [
                {
                    id: 'billing.index',
                    title: "ทั้งหมด",
                    icon: "bi bi-collection",
                    router: "/billing/index"
                },
                {
                    id: 'billing.board',
                    title: "Board",
                    icon: "bi bi-kanban-fill",
                    router: "/billing/board"
                },
                {
                    id: 'billing.todo',
                    title: "Todo",
                    icon: "bi bi-calendar3",
                    router: "/billing/todo"
                },
                {
                    id: 'billing.service_items',
                    title: "ของซื้อของขาย",
                    icon: "bi bi-cart",
                    router: "/billing/service_items"
                },
                {
                    id: 'billing.verify',
                    title: "ยืนยันยอด",
                    icon: "bi bi-hand-thumbs-up",
                    router: "/billing/verify",
                    only: ['root', 'manager'],
                },
                {
                    id: 'billing.trial',
                    title: "ยืนยันอีกครั้ง",
                    icon: "bi bi-card-checklist",
                    router: "/billing/trial",
                    only: ['root', 'manager'],
                },
                {
                    id: 'billing.verified',
                    title: "ยืนยันแล้ว",
                    icon: "bi bi-check-circle",
                    router: "/billing/verified",
                    only: ['root', 'manager'],
                },
            ] as Menu[],
        },
        {
            id: "platform",
            title:  "Platform",
            icon: "bi bi-speedometer",
            children: [
                {
                    id: 'platform.dashboard',
                    title: "Dashboard",
                    icon: "bi bi-bar-chart",
                    router: "/platform/dashboard"
                },
                {
                    id: 'platform.facebook',
                    title: "Facebook",
                    icon: "bi bi-facebook",
                    router: "/platform/facebook"
                },
                {
                    id: 'platform.instagram',
                    title: "Instagram",
                    icon: "bi bi-instagram",
                    router: "/platform/instagram"
                },
                {
                    id: 'platform.tiktok',
                    title: "Tiktok",
                    icon: "bi bi-tiktok",
                    router: "/platform/tiktok"
                },
                {
                    id: 'platform.import',
                    title: "Import file",
                    icon: "bi bi-cloud-download",
                    router: "/platform/import",
                    only: ['root', 'manager'],
                },
            ] as Menu[],
        },
        {
            id: "note",
            title:  "Notes",
            icon: "bi bi-journal-text",
            router: "/notes"
        },
        {
            id: "leave",
            title:  "ลาหยุด",
            icon: "bi bi-person-walking",
            children: [
                {
                    id: 'leave.dashboard',
                    title: "ภาพรวม",
                    icon: "bi bi-chart",
                    router: "/leave/dashboard",
                    only: ['root', 'manager'],
                },
                {
                    id: 'leave.all',
                    title: "ทั้งหมด",
                    icon: "bi bi-person-walking",
                    router: "/leave/all"
                },
            ] as Menu[],
        },
        {
            id: "whale_chess",
            title:  "Game",
            icon: "bi bi-joystick",
            router: "/whale-chess/lobby"
        },
        {
            id: "users",
            title:  "Users",
            icon: "bi bi-people",
            router: "/users"
        },
        {
            id: "scb",
            title:  "SCB",
            icon: "bi bi-bank",
            only: ['root', 'manager'],
            children: [
                {
                    id: 'scb.statement',
                    title: "Statement",
                    icon: "bi bi-list",
                    router: "/scb/statement"
                },
                {
                    id: 'scb.import',
                    title: "Import",
                    icon: "bi bi-cloud-download",
                    router: "/scb/import"
                },
            ] as Menu[],
        },
        {
            id: 'setting',
            title: "Setting",
            icon: "bi bi-house-gear",
            router: "/setting",
        },
    ] as Menu[]

export const useMenuStore = defineStore('menu', {
    state: () => {
        return {
            breadcrumbs: [] as Breadcrumb[],
            // items: items
        }
    },
    getters: {
        authorizedMenus(state) {
            const userStore = useUserStore()
            const menus = clone(items)
            // const { roles } = storeToRefs(userStore)
            const roles = userStore.roles ?? []
            authorize(menus, roles)
            return menus
        },
    },
    actions: {

    },
});

function haveCommonElement(arr1: any[], arr2: any[]) {
    return arr1.some(element => arr2.includes(element));
}

const authorize = (menus: Menu[], roles: string[]): Menu[] => {
    for (let i = 0; i < menus.length; i++) {
        const menu = menus[i]
        if(!isAllowed(menu, roles)) {
            menus.splice(i, 1);
            i--
        } else if(menu.children && menu.children.length > 0) {
            const children = menu.children
            authorize(children, roles)
        }
    }
    return menus
}

const isAllowed = (menu: Menu, roles: string[]): boolean => {
    if(menu.only && menu.only.length > 0) {
        const only = menu.only
        return haveCommonElement(only, roles)
    } else if(menu.except && menu.except.length > 0) {
        const except = menu.except
        return haveCommonElement(except, roles)
    }
    return true
}
